import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, useCustomTheme } from './src/styles/theme';
import { I18nextProvider } from 'react-i18next';
import i18n from './src/i18n';

export const UseWrapWithProvider = ({
	element,
}: {
	element: React.ReactNode;
}) => {
	const theme = useCustomTheme();

	return (
		<I18nextProvider i18n={i18n}>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				{element}
			</ThemeProvider>
		</I18nextProvider>
	);
};
