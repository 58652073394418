import { createGlobalStyle } from 'styled-components';

// Typescript ------------------------------------------------------------------
export interface ThemeType {
  breakpoints: {
    tablet: string;
    desktop: string;
  };
  colors: {
    primaryBlue: string;
    secondaryBlue: string;
    transparentSecondaryBlue: string;
    softBlack: string;
    softWhite: string;
    orangeCTA: string;
    black: string;
    white: string;
    transparentWhite: string;
    grey: string;
  };
  shadows: {
    blue: string;
    grey: string;
  };
  borderRadius: string;
  typography: {
    fontFamily: {
      headingFont: string;
      bodyFont: string;
    };
    lineHeight: {
      [key: string]: {
        default: number;
        tablet: number;
        desktop: number;
      };
    };
    fontSize: {
      [key: string]: {
        default: string;
        tablet: string;
        desktop: string;
      };
    };
    fontWeight: {
      default: string;
      light: number;
      bold: string;
    };
    letterSpacing: {
      default: string;
      tablet: string;
      desktop: string;
    };
  };
  spacing: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
    xxl: string;
  };
}
// -----------------------------------------------------------------------------

export const useCustomTheme = (): ThemeType => ({
  breakpoints: {
    tablet: '768px',
    desktop: '1024px',
  },
  colors: {
    primaryBlue: '#0080B2',
    secondaryBlue: '#8BD3F5',
    transparentSecondaryBlue: 'rgba(139, 211, 245, 0.3)',
    softBlack: '#1C1C1C',
    softWhite: '#F5F5F5',
    orangeCTA: '#FF7F50',
    black: '#000000',
    white: '#FFFFFF',
    transparentWhite: 'rgba(255, 255, 255, 0.7)',
    grey: '#A0A0A0',
  },
  shadows: {
    blue: '0px 4px 20px 5px rgba(155, 209, 240, 1)',
    grey: '0px 4px 20px 5px rgba(160, 160, 160, 0.55)',
  },
  borderRadius: '15px',
  typography: {
    fontFamily: {
      headingFont: 'Merriweather, serif',
      bodyFont: 'Nunito, sans-serif',
    },
    lineHeight: {
      h1: {
        default: 1.2,
        tablet: 1.15,
        desktop: 1.1,
      },
      h2: {
        default: 1.25,
        tablet: 1.2,
        desktop: 1.15,
      },
      h3: {
        default: 1.3,
        tablet: 1.25,
        desktop: 1.2,
      },
      h4: {
        default: 1.35,
        tablet: 1.3,
        desktop: 1.25,
      },
      h5: {
        default: 1.4,
        tablet: 1.35,
        desktop: 1.3,
      },
      h6: {
        default: 1.45,
        tablet: 1.4,
        desktop: 1.35,
      },
      p: {
        default: 1.5,
        tablet: 1.45,
        desktop: 1.4,
      },
    },
    fontSize: {
      h1: {
        default: 'clamp(2rem, 7vw, 2.5rem)',
        tablet: 'clamp(2.5rem, 7vw, 3rem)',
        desktop: 'clamp(3rem, 7vw, 3.5rem)',
      },
      h2: {
        default: 'clamp(1.75rem, 6vw, 2.25rem)',
        tablet: 'clamp(2rem, 6vw, 2.75rem)',
        desktop: 'clamp(2.25rem, 6vw, 3rem)',
      },
      h3: {
        default: 'clamp(1.5rem, 5vw, 2rem)',
        tablet: 'clamp(1.75rem, 5vw, 2.25rem)',
        desktop: 'clamp(2rem, 5vw, 2.5rem)',
      },
      h4: {
        default: 'clamp(1.25rem, 4vw, 1.75rem)',
        tablet: 'clamp(1.5rem, 4vw, 2rem)',
        desktop: 'clamp(1.75rem, 4vw, 2.25rem)',
      },
      h5: {
        default: 'clamp(1.1rem, 3vw, 1.5rem)',
        tablet: 'clamp(1.25rem, 3vw, 1.75rem)',
        desktop: 'clamp(1.5rem, 3vw, 2rem)',
      },
      h6: {
        default: 'clamp(1rem, 2vw, 1.25rem)',
        tablet: 'clamp(1.1rem, 2vw, 1.5rem)',
        desktop: 'clamp(1.25rem, 2vw, 1.75rem)',
      },
      p: {
        default: '1rem',
        tablet: '1.125rem',
        desktop: '1.25rem',
      },
    },
    fontWeight: {
      default: 'normal',
      light: 300,
      bold: 'bold',
    },
    letterSpacing: {
      default: '0.05em',
      tablet: '0.04em',
      desktop: '0.03em',
    },
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
  },
});

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    height: 100%;
    color: ${({ theme }) => theme.colors.softBlack};
    background: ${({ theme }) => theme.colors.white};
    overflow-x: hidden;
    font-family: ${({ theme }) => theme.typography.fontFamily.bodyFont};
  }

  .block-style-h1,
  .block-style-h2,
  .block-style-h3,
  .block-style-h4,
  .block-style-h5,
  .block-style-h6 {
    font-family: ${({ theme }) => theme.typography.fontFamily.headingFont};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
    letter-spacing: ${({ theme }) => theme.typography.letterSpacing.default};
  }

  .block-style-h1 {
    font-size: ${({ theme }) => theme.typography.fontSize.h1.default};
    line-height: ${({ theme }) => theme.typography.lineHeight.h1.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h1.tablet};
      line-height: ${({ theme }) => theme.typography.lineHeight.h1.tablet};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.tablet};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h1.desktop};
      line-height: ${({ theme }) => theme.typography.lineHeight.h1.desktop};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.desktop};
    }
  }

  .block-style-h2 {
    font-size: ${({ theme }) => theme.typography.fontSize.h2.default};
    line-height: ${({ theme }) => theme.typography.lineHeight.h2.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h2.tablet};
      line-height: ${({ theme }) => theme.typography.lineHeight.h2.tablet};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.tablet};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h2.desktop};
      line-height: ${({ theme }) => theme.typography.lineHeight.h2.desktop};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.desktop};
    }
  }

  .block-style-h3 {
    font-size: ${({ theme }) => theme.typography.fontSize.h3.default};
    line-height: ${({ theme }) => theme.typography.lineHeight.h3.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h3.tablet};
      line-height: ${({ theme }) => theme.typography.lineHeight.h3.tablet};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.tablet};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h3.desktop};
      line-height: ${({ theme }) => theme.typography.lineHeight.h3.desktop};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.desktop};
    }
  }

  .block-style-h4 {
    font-size: ${({ theme }) => theme.typography.fontSize.h4.default};
    line-height: ${({ theme }) => theme.typography.lineHeight.h4.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h4.tablet};
      line-height: ${({ theme }) => theme.typography.lineHeight.h4.tablet};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.tablet};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h4.desktop};
      line-height: ${({ theme }) => theme.typography.lineHeight.h4.desktop};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.desktop};
    }
  }

  .block-style-h5 {
    font-size: ${({ theme }) => theme.typography.fontSize.h5.default};
    line-height: ${({ theme }) => theme.typography.lineHeight.h5.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h5.tablet};
      line-height: ${({ theme }) => theme.typography.lineHeight.h5.tablet};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.tablet};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h5.desktop};
      line-height: ${({ theme }) => theme.typography.lineHeight.h5.desktop};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.desktop};
    }
  }

  .block-style-h6 {
    font-size: ${({ theme }) => theme.typography.fontSize.h6.default};
    line-height: ${({ theme }) => theme.typography.lineHeight.h6.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h6.tablet};
      line-height: ${({ theme }) => theme.typography.lineHeight.h6.tablet};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.tablet};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: ${({ theme }) => theme.typography.fontSize.h6.desktop};
      line-height: ${({ theme }) => theme.typography.lineHeight.h6.desktop};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.desktop};
    }
  }

  .block-style-normal {
    font-size: ${({ theme }) => theme.typography.fontSize.p.default};
    line-height: ${({ theme }) => theme.typography.lineHeight.p.default};
    font-family: ${({ theme }) => theme.typography.fontFamily.bodyFont};
    font-weight: ${({ theme }) => theme.typography.fontWeight.default};
    letter-spacing: ${({ theme }) => theme.typography.letterSpacing.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      font-size: ${({ theme }) => theme.typography.fontSize.p.tablet};
      line-height: ${({ theme }) => theme.typography.lineHeight.p.tablet};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.tablet};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
      font-size: ${({ theme }) => theme.typography.fontSize.p.desktop};
      line-height: ${({ theme }) => theme.typography.lineHeight.p.desktop};
      letter-spacing: ${({ theme }) => theme.typography.letterSpacing.desktop};
    }
  }
`;
