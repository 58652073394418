import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationIT from './locales/it/translations.json';
import translationEN from './locales/en/translations.json';

i18n.use(initReactI18next).init({
  resources: {
    it: { translation: translationIT },
    en: { translation: translationEN },
  },
  lng: 'it',
  fallbackLng: 'it',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  keySeparator: '.',
  nsSeparator: false,
});

console.log('Translations loaded:', {
  it: translationIT,
  en: translationEN,
});

export default i18n;
